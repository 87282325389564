<template>
    <dialog
        v-if="show"
        ref="root"
        class="focus-trap"
        :role="role"
        :class="{ modal: fullscreen }"
        @vue:mounted="$refs.root[method] && $refs.root[method]()"
        @close.prevent="close"
    >
        <button autofocus class="visually-hidden" />
        <slot />
    </dialog>
</template>

<script>
export default {
    name: 'FocusTrap',
    props: {
        role: { type: String, default: null },
        closeDelay: { type: Number, default: 0 },
        fullscreen: { type: Boolean, default: true },
        preventEscape: { type: Boolean, default: false }
    },
    emits: ['open', 'close', 'beforeclose'],
    data() {
        return {
            // Track element used to focus the modal so we can provide focus back to it
            entryElement: null,
            show: false
        };
    },
    computed: {
        method() {
            return this.fullscreen ? 'showModal' : 'show';
        }
    },
    mounted() {
        this.open();
        if (this.preventEscape) {
            window.addEventListener('keydown', this.onEscape);
        }
    },
    activated() {
        this.open();
        if (this.preventEscape) {
            window.addEventListener('keydown', this.onEscape);
        }
    },
    deactivated() {
        this.close();
    },
    beforeUnmount() {
        this.close();
    },
    methods: {
        open() {
            this.entryElement = document.activeElement;
            this.$emit('open');
            this.show = true;
        },
        close() {
            if (!this.show) {
                return;
            }
            this.$emit('beforeclose');
            this.$timeout(() => {
                if (this.$refs.root && this.$refs.root.close) {
                    this.$refs.root.close();
                }
                if (this.entryElement) {
                    this.entryElement.focus();
                }
                this.show = false;
                this.$emit('close');
            }, this.closeDelay);
        },
        onEscape(e) {
            if (e.key === 'Escape') e.preventDefault();
        }
    }
};
</script>

<style lang="less">
.focus-trap {
    border: none;
    margin: 0;
    padding: 0;

    position: static;
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    max-width: unset;
    max-height: unset;
    &.modal {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
    &::backdrop {
        display: none;
    }
}
</style>
